import React from "react"
import ReactMarkdown from "react-markdown";
import SEO from "../components/common/seo"
import Layout from "../components/common/layout";
import {Link} from "@reach/router";
import {graphql} from "gatsby";
import {customElements} from "../utils/global-functions";
import rehypeRaw from "rehype-raw";

const PoliticsPage = ({data, pageContext}) => {
    const lang = pageContext.locale.path.default;
    const pc = data.allStrapiPoliticasCorporativas.edges[0].node;

    return (
        <Layout pageContext={pageContext} cookies={data.cookies}>
            <SEO lang={lang} title={pc.seo.title} description={pc.seo.meta_description}/>

            <section data-jarallax="" data-speed=".8" className="pt-12 pb-10 pt-md-15 pb-md-14 bg-white"
                     style={{background: "linear-gradient(rgba(0, 0, 0, 0.4),rgba(20, 0, 0, 0.4), rgba(20, 0, 0, 0.4)), url(" + require('../images/politicas.jpg').default + ") no-repeat center center"}}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-md-8 col-lg-8">
                            {/** Heading */}
                            <h1 className="display-2 font-weight-black text-white spacing">
                                {pc.title}
                            </h1>
                        </div>
                    </div>
                </div>
                {/** / .row */}
                {/** / .container */}
            </section>

            {/**
             ================================================== */
            }
            <section className="mt-10 py-md-6 mb-5 bg-white">
                <div className="container">


                    <div className="row align-items-center">
                        <div className="col-12 col-md-12 col-lg-7" data-aos="fade-right">

                            {/** Only Card */}
                            <div className="card border-card mb-2">
                                <div className="card-body my-auto">
                                    <div className="icons2 text-primary mb-2 mt-5 ml-3">
                                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1"
                                             xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                            <title>Icon For Group</title>
                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <polygon points="0 0 24 0 24 24 0 24" opacity="0"/>
                                                <path
                                                    d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
                                                    fill="#F4364C" fillRule="nonzero" opacity="0.3"/>
                                                <path
                                                    d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
                                                    fill="#F4364C" fillRule="nonzero"/>
                                            </g>
                                        </svg>
                                    </div>
                                    <h2 className="display-5 font-weight-bold spacing mt-5 ml-4">
                                        {pc.ethical_title}
                                    </h2>

                                    <ReactMarkdown children={pc.ethical_description} rehypePlugins={[rehypeRaw]} components={customElements('font-size-lg text-black-80 mt-8 ml-4 mr-4 mb-6 spacing')}/>

                                    {/*<br/>
                                    {pc.ethical_button_link === '#' && <div align="center" className="spacing disponible">
                                        <b>{pc.ethical_button_text}</b>
                                    </div>}

                                    {pc.ethical_button_link !== '#' && <Link to={pc.ethical_button_link} align="center" className="spacing disponible d-block">
                                        <b>{pc.ethical_button_text}</b>
                                    </Link>}*/}

                                    <br/>
                                </div>
                            </div>
                        </div>


                        <div className="col-12 col-md-12 col-lg-4 offset-lg-1">

                            {/** Decoration */}
                            <div className="position-absolute top-center top-md-left text-warning mt-n2 ml-md-n8"
                                 style={{position: "absolute", zIndex: -10, top: "50px"}}>
                                <svg width="185" height="186" viewBox="0 0 185 186" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="2" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="22" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="42" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="62" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="82" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="102" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="122" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="142" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="162" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="182" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="2" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="22" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="42" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="62" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="82" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="102" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="122" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="142" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="162" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="182" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="2" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="22" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="42" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="62" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="82" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="102" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="122" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="142" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="162" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="182" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="2" cy="62" r="2" fill="#f43c51"/>
                                    <circle cx="22" cy="62" r="2" fill="#f43c51"/>
                                    <circle cx="42" cy="62" r="2" fill="#f43c51"/>
                                    <circle cx="62" cy="62" r="2" fill="#f43c51"/>
                                    <circle cx="82" cy="62" r="2" fill="#f43c51"/>
                                    <circle cx="102" cy="62" r="2" fill="#f43c51"/>
                                    <circle cx="122" cy="62" r="2" fill="#f43c51"/>
                                    <circle cx="142" cy="62" r="2" fill="#f43c51"/>
                                    <circle cx="162" cy="62" r="2" fill="#f43c51"/>
                                    <circle cx="182" cy="62" r="2" fill="#f43c51"/>
                                    <circle cx="2" cy="82" r="2" fill="#f43c51"/>
                                    <circle cx="22" cy="82" r="2" fill="#f43c51"/>
                                    <circle cx="42" cy="82" r="2" fill="#f43c51"/>
                                    <circle cx="62" cy="82" r="2" fill="#f43c51"/>
                                    <circle cx="82" cy="82" r="2" fill="#f43c51"/>
                                    <circle cx="102" cy="82" r="2" fill="#f43c51"/>
                                    <circle cx="122" cy="82" r="2" fill="#f43c51"/>
                                    <circle cx="142" cy="82" r="2" fill="#f43c51"/>
                                    <circle cx="162" cy="82" r="2" fill="#f43c51"/>
                                    <circle cx="182" cy="82" r="2" fill="#f43c51"/>
                                    <circle cx="2" cy="102" r="2" fill="#f43c51"/>
                                    <circle cx="22" cy="102" r="2" fill="#f43c51"/>
                                    <circle cx="42" cy="102" r="2" fill="#f43c51"/>
                                    <circle cx="62" cy="102" r="2" fill="#f43c51"/>
                                    <circle cx="82" cy="102" r="2" fill="#f43c51"/>
                                    <circle cx="102" cy="102" r="2" fill="#f43c51"/>
                                    <circle cx="122" cy="102" r="2" fill="#f43c51"/>
                                    <circle cx="142" cy="102" r="2" fill="#f43c51"/>
                                    <circle cx="162" cy="102" r="2" fill="#f43c51"/>
                                    <circle cx="182" cy="102" r="2" fill="#f43c51"/>
                                    <circle cx="2" cy="122" r="2" fill="#f43c51"/>
                                    <circle cx="22" cy="122" r="2" fill="#f43c51"/>
                                    <circle cx="42" cy="122" r="2" fill="#f43c51"/>
                                    <circle cx="62" cy="122" r="2" fill="#f43c51"/>
                                    <circle cx="82" cy="122" r="2" fill="#f43c51"/>
                                    <circle cx="102" cy="122" r="2" fill="#f43c51"/>
                                    <circle cx="122" cy="122" r="2" fill="#f43c51"/>
                                    <circle cx="142" cy="122" r="2" fill="#f43c51"/>
                                    <circle cx="162" cy="122" r="2" fill="#f43c51"/>
                                    <circle cx="182" cy="122" r="2" fill="#f43c51"/>
                                    <circle cx="2" cy="142" r="2" fill="#f43c51"/>
                                    <circle cx="22" cy="142" r="2" fill="#f43c51"/>
                                    <circle cx="42" cy="142" r="2" fill="#f43c51"/>
                                    <circle cx="62" cy="142" r="2" fill="#f43c51"/>
                                    <circle cx="82" cy="142" r="2" fill="#f43c51"/>
                                    <circle cx="102" cy="142" r="2" fill="#f43c51"/>
                                    <circle cx="122" cy="142" r="2" fill="#f43c51"/>
                                    <circle cx="142" cy="142" r="2" fill="#f43c51"/>
                                    <circle cx="162" cy="142" r="2" fill="#f43c51"/>
                                    <circle cx="182" cy="142" r="2" fill="#f43c51"/>
                                    <circle cx="2" cy="162" r="2" fill="#f43c51"/>
                                    <circle cx="22" cy="162" r="2" fill="#f43c51"/>
                                    <circle cx="42" cy="162" r="2" fill="#f43c51"/>
                                    <circle cx="62" cy="162" r="2" fill="#f43c51"/>
                                    <circle cx="82" cy="162" r="2" fill="#f43c51"/>
                                    <circle cx="102" cy="162" r="2" fill="#f43c51"/>
                                    <circle cx="122" cy="162" r="2" fill="#f43c51"/>
                                    <circle cx="142" cy="162" r="2" fill="#f43c51"/>
                                    <circle cx="162" cy="162" r="2" fill="#f43c51"/>
                                    <circle cx="182" cy="162" r="2" fill="#f43c51"/>
                                    <circle cx="2" cy="182" r="2" fill="#f43c51"/>
                                    <circle cx="22" cy="182" r="2" fill="#f43c51"/>
                                    <circle cx="42" cy="182" r="2" fill="#f43c51"/>
                                    <circle cx="62" cy="182" r="2" fill="#f43c51"/>
                                    <circle cx="82" cy="182" r="2" fill="#f43c51"/>
                                    <circle cx="102" cy="182" r="2" fill="#f43c51"/>
                                    <circle cx="122" cy="182" r="2" fill="#f43c51"/>
                                    <circle cx="142" cy="182" r="2" fill="#f43c51"/>
                                    <circle cx="162" cy="182" r="2" fill="#f43c51"/>
                                    <circle cx="182" cy="182" r="2" fill="#f43c51"/>
                                </svg>
                            </div>

                            {/** Image */}
                            <img src={require("../images/policy--img.jpg").default} alt="..."
                                 className="img-fluid mw-130 float-left mb-6 mb-md-0"
                                 data-aos="fade-left"/>
                        </div>

                    </div>
                    {/** / .row */}

                </div>
            </section>


            {/**
             ================================================== */}
            <section className="mt-7 py-md-10 mb-2 bg-white border-top">
                <div className="container">

                    <div className="row align-items-center">
                        <div className="col-12 col-md-12 col-lg-12" data-aos="fade-right">

                            {/** Only Card */}
                            <div className="card border-card mb-2">
                                <div className="card-body my-auto">
                                    <div className="icons2 text-primary mb-2 mt-5 ml-3">
                                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1"
                                             xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                            <title>Icon For Binocular</title>
                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <rect opacity="0" x="0" y="0" width="24" height="24"/>
                                                <path
                                                    d="M12.8434797,16 L11.1565203,16 L10.9852159,16.6393167 C10.3352654,19.064965 7.84199997,20.5044524 5.41635172,19.8545019 C2.99070348,19.2045514 1.55121603,16.711286 2.20116652,14.2856378 L3.92086709,7.86762789 C4.57081758,5.44197964 7.06408298,4.00249219 9.48973122,4.65244268 C10.5421727,4.93444352 11.4089671,5.56345262 12,6.38338695 C12.5910329,5.56345262 13.4578273,4.93444352 14.5102688,4.65244268 C16.935917,4.00249219 19.4291824,5.44197964 20.0791329,7.86762789 L21.7988335,14.2856378 C22.448784,16.711286 21.0092965,19.2045514 18.5836483,19.8545019 C16.158,20.5044524 13.6647346,19.064965 13.0147841,16.6393167 L12.8434797,16 Z M17.4563502,18.1051865 C18.9630797,18.1051865 20.1845253,16.8377967 20.1845253,15.2743923 C20.1845253,13.7109878 18.9630797,12.4435981 17.4563502,12.4435981 C15.9496207,12.4435981 14.7281751,13.7109878 14.7281751,15.2743923 C14.7281751,16.8377967 15.9496207,18.1051865 17.4563502,18.1051865 Z M6.54364977,18.1051865 C8.05037928,18.1051865 9.27182488,16.8377967 9.27182488,15.2743923 C9.27182488,13.7109878 8.05037928,12.4435981 6.54364977,12.4435981 C5.03692026,12.4435981 3.81547465,13.7109878 3.81547465,15.2743923 C3.81547465,16.8377967 5.03692026,18.1051865 6.54364977,18.1051865 Z"
                                                    fill="#F4364C"/>
                                            </g>
                                        </svg>
                                    </div>
                                    <h2 className="display-5 font-weight-bold spacing mt-5 ml-4">
                                        {pc.anticorruption_title}
                                    </h2>
                                    <ReactMarkdown children={pc.anticorruption_description} rehypePlugins={[rehypeRaw]} components={customElements('font-size-lg text-black-80 mt-8 ml-4 mr-4 mb-6 spacing')} />

                                    <div align="left">
                                        <a href={pc.anticorruption_pdf.localFile.publicURL} target="_blank" rel="noreferrer noopener" className="btn btn-primary spacing ml-4 mb-5">Ver PDF</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    {/** / .row */}

                </div>
            </section>

            {/** ================================================== */}
            <section className="bg-grey py-7 py-md-9">
                <div className="container">

                    <div className="row align-items-center mt-7">
                        <div className="col-12 col-md-6 col-lg-6" data-aos="fade-right">

                            {/** Only Card */}
                            <div className="card border-card mb-6">
                                <div className="card-body my-auto">
                                    {/** Icon */}
                                    <div className="icons text-primary mb-2 mt-5 ml-3">
                                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1"
                                             xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                            <title>Icon For Flower#1</title>
                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <polygon opacity="0" points="0 0 24 0 24 24 0 24"/>
                                                <path
                                                    d="M9,1 C4.92361111,7.82926829 4.92361111,12.8292683 9,16 C12.9097222,12.8292683 12.9097222,7.82926829 9,1 Z"
                                                    fill="#F4364C" opacity="0.3"
                                                    transform="translate(8.937500, 8.500000) scale(-1, 1) rotate(-330.000000) translate(-8.937500, -8.500000) "/>
                                                <path
                                                    d="M15,1 C10.9236111,7.82926829 10.9236111,12.8292683 15,16 C18.9097222,12.8292683 18.9097222,7.82926829 15,1 Z"
                                                    fill="#F4364C" opacity="0.3"
                                                    transform="translate(14.937500, 8.500000) rotate(-330.000000) translate(-14.937500, -8.500000) "/>
                                                <path
                                                    d="M12,1 C7.92361111,7.82926829 7.92361111,12.8292683 12,16 C15.9097222,12.8292683 15.9097222,7.82926829 12,1 Z"
                                                    fill="#F4364C" opacity="0.3"/>
                                                <path
                                                    d="M6.34403065,13 L17.6559693,13 C18.2082541,13 18.6559693,13.4477153 18.6559693,14 C18.6559693,14.0973246 18.6417616,14.1941279 18.6137956,14.2873479 L16.4275913,21.5746958 C16.1738009,22.4206637 15.3951551,23 14.5119387,23 L9.4880613,23 C8.60484486,23 7.82619911,22.4206637 7.57240873,21.5746958 L5.38620437,14.2873479 C5.22750651,13.758355 5.52768992,13.2008716 6.05668277,13.0421737 C6.14990279,13.0142077 6.24670609,13 6.34403065,13 Z"
                                                    fill="#F4364C"/>
                                            </g>
                                        </svg>
                                    </div>
                                    <h2 className="display-5 font-weight-bold spacing mt-4 ml-4">
                                        {pc.quality_title}
                                    </h2>
                                    <ReactMarkdown children={pc.quality_description} rehypePlugins={[rehypeRaw]} components={customElements('font-size-lg text-black-80 mt-8 ml-4 mr-4 mb-6 spacing sub--policy')} />

                                    <br/>

                                    <div align="left">
                                        <Link to="es/politicas/calidad" className="btn btn-primary spacing ml-4 mb-5">
                                            Más info
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 col-lg-6" data-aos="fade-left">

                            {/** Only Card */}
                            <div className="card border-card mb-6">
                                <div className="card-body my-auto">
                                    <div className="icons text-primary mb-2 mt-5 ml-3">
                                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1"
                                             xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                            <title>Icon For Box#3</title>
                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <rect opacity="0" x="0" y="0" width="24" height="24"/>
                                                <path
                                                    d="M20.4061385,6.73606154 C20.7672665,6.89656288 21,7.25468437 21,7.64987309 L21,16.4115967 C21,16.7747638 20.8031081,17.1093844 20.4856429,17.2857539 L12.4856429,21.7301984 C12.1836204,21.8979887 11.8163796,21.8979887 11.5143571,21.7301984 L3.51435707,17.2857539 C3.19689188,17.1093844 3,16.7747638 3,16.4115967 L3,7.64987309 C3,7.25468437 3.23273352,6.89656288 3.59386153,6.73606154 L11.5938615,3.18050598 C11.8524269,3.06558805 12.1475731,3.06558805 12.4061385,3.18050598 L20.4061385,6.73606154 Z"
                                                    fill="#F4364C" opacity="0.3"/>
                                                <polygon fill="#F4364C"
                                                         points="14.9671522 4.22441676 7.5999999 8.31727912 7.5999999 12.9056825 9.5999999 13.9056825 9.5999999 9.49408582 17.25507 5.24126912"/>
                                            </g>
                                        </svg>
                                    </div>
                                    <h2 className="display-5 font-weight-bold spacing mt-4 ml-4">
                                        {pc.vendors_title}
                                    </h2>
                                    <ReactMarkdown children={pc.vendors_description} rehypePlugins={[rehypeRaw]} components={customElements('font-size-lg text-black-80 mt-8 ml-4 mr-4 mb-6 spacing  sub--policy')} />

                                    <br/>

                                    <div align="left">
                                        <Link to="es/politicas/proveedores" className="btn btn-primary spacing ml-4 mb-5">
                                            Más info
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/** / .row */}

                </div>
            </section>
        </Layout>
    );
}

export default PoliticsPage

export const politicsPageQuery = graphql`
query politicsPageQuery($lang: String!){
    allStrapiPoliticasCorporativas {
        edges {
            node {
                seo {
                    title
                    meta_description
                }
                title
                ethical_title
                ethical_description
                ethical_button_text
                ethical_button_link
                anticorruption_title
                anticorruption_description
                anticorruption_pdf {
                    localFile {
                        publicURL
                    }
                }
                quality_title
                quality_description
                vendors_title
                vendors_description
            }
        }
    }
    cookies: markdownRemark(
        frontmatter: {
            lang: {
            eq: $lang
        }
        name: { eq: "cookies" }
    }) {
        frontmatter {
            cookiesStart
            cookiesEnd
            linkText
        }
    }
}`;

